/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/css/all.min.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: #111827;
  background-color: #f9fafb;
  line-height: 1.5;
}

.confirmation-dialog-container .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }

